import {
  Box,
  CloseButton,
  Heading,
  HStack,
  Icon,
  IconButton,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import {
  MdCheckCircle,
  MdChevronRight,
  MdRadioButtonUnchecked,
} from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { sumBy } from "lodash";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ProfilesQuery,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from "../generated/graphql";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useAuth } from "../Auth/useAuth.ts";
import { profileFragments } from "../User/useProfile.tsx";

const steps: {
  title: string;
  link: string;
  status: OnboardingStatus;
  isComplete: (status: OnboardingStatus) => boolean;
}[] = [
  {
    title: t("onboardingChecklist.verifyEmail"),
    link: "/onboarding/verify-email",
    status: "EMAIL_VERIFIED",
    isComplete(status) {
      return [
        "EMAIL_VERIFIED",
        "PROFILE_SETUP",
        "BUDGET_SETUP",
        "ACCOUNT_LINKING",
        "COMPLETE",
      ].includes(status);
    },
  },
  {
    title: t("onboardingChecklist.setupProfile"),
    link: "/onboarding/setup-profile",
    status: "PROFILE_SETUP",
    isComplete(status) {
      return [
        "PROFILE_SETUP",
        "BUDGET_SETUP",
        "ACCOUNT_LINKING",
        "COMPLETE",
      ].includes(status);
    },
  },
  {
    title: t("onboardingChecklist.setupBudget"),
    link: "/onboarding/setup-budget",
    status: "BUDGET_SETUP",
    isComplete(status) {
      return ["BUDGET_SETUP", "ACCOUNT_LINKING", "COMPLETE"].includes(status);
    },
  },
  {
    title: t("onboardingChecklist.linkAccount"),
    link: "/onboarding/link-account",
    status: "ACCOUNT_LINKING",
    isComplete(status) {
      return ["ACCOUNT_LINKING", "COMPLETE"].includes(status);
    },
  },
];

export const OnboardingChecklist = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { data, loading } = useQuery<ProfilesQuery>(QUERY, {
    variables: { user_id: user?.uid },
    skip: !user,
  });

  const onBoardingStatus = data?.profiles[0]?.onboarding_status;
  const navigate = useNavigate();

  const progress = useMemo(
    () => sumBy(steps, (step) => Number(step.isComplete(onBoardingStatus))),
    [onBoardingStatus],
  );

  if (loading || !data) {
    return null;
  }

  if (onBoardingStatus === "REMOVED") {
    return <Navigate to={"/dashboard"} />;
  }

  if (onBoardingStatus === "COMPLETE") {
    return <OnboardingChecklistSuccess />;
  }

  return (
    <VStack
      w={"container.sm"}
      spacing={4}
      alignItems={"stretch"}
      layerStyle={"frame"}
    >
      <Heading textStyle={"sectionTitle"} size={"md"}>
        {t("onboardingChecklist.title")}
      </Heading>
      <Text color={"casBlueGrey"}>
        {t("onboardingChecklist.description", { appName: "Cashbah" })}
      </Text>
      <HStack
        spacing={2}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={3}
        aria-label={t("onboardingChecklist.progressLabel")}
      >
        <HStack align={"center"} flex={1}>
          {steps.map((step) => (
            <Box
              key={step.title}
              aria-label={step.title}
              aria-hidden
              bg={step.isComplete(onBoardingStatus) ? "green.500" : "gray.200"}
              h={2}
              flex={1}
              borderRadius="lg"
            />
          ))}
        </HStack>
        <Text fontSize={"sm"} color={"casBlueGrey"}>
          {t("common.step", { count: steps.length })} {progress} /{" "}
          {steps.length}
        </Text>
      </HStack>
      <List spacing={2}>
        {steps.map((step) => {
          return (
            <ListItem key={step.title}>
              <HStack>
                <Icon
                  as={
                    step.isComplete(onBoardingStatus)
                      ? MdCheckCircle
                      : MdRadioButtonUnchecked
                  }
                  boxSize={6}
                  color={"green.500"}
                />
                <Text color={"blackAlpha.800"} flex={1}>
                  {step.title}
                </Text>
                {!step.isComplete(onBoardingStatus) ? (
                  <IconButton
                    variant={"ghost"}
                    colorScheme={"blackAlpha"}
                    icon={<Icon as={MdChevronRight} boxSize={6} />}
                    aria-label={step.title}
                    onClick={() => navigate(step.link)}
                  />
                ) : null}
              </HStack>
            </ListItem>
          );
        })}
      </List>
    </VStack>
  );
};

const OnboardingChecklistSuccess = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [updateProfile] = useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UPDATE_PROFILE, {
    refetchQueries: ["Dashboard"],
  });

  const handleClose = async () => {
    // update onbarding status to REMOVED
    await updateProfile({
      variables: {
        user_id: user?.uid as string,
        onboarding_status: "REMOVED",
      },
    });
  };

  return (
    <VStack
      backgroundColor={"white"}
      w={"full"}
      borderRadius="xl"
      p={4}
      spacing={4}
      alignItems={"stretch"}
      position={"relative"}
    >
      <CloseButton
        position={"absolute"}
        top={2}
        right={2}
        onClick={handleClose}
      />
      <Heading textStyle={"sectionTitle"} size={"md"}>
        {t("onboardingChecklistSuccess.title")}
      </Heading>
      <Text color={"casBlueGrey"}>
        {t("onboardingChecklistSuccess.description")}
      </Text>
      <HStack
        spacing={2}
        role="progressbar"
        aria-valuenow={100}
        aria-valuemin={0}
        aria-valuemax={3}
        aria-label={t("onboardingChecklist.progressLabel")}
      >
        <HStack align={"center"} flex={1}>
          {steps.map((step) => (
            <Box
              key={step.title}
              aria-label={step.title}
              aria-hidden
              bg={"green.500"}
              h={2}
              flex={1}
              borderRadius="lg"
            />
          ))}
        </HStack>
        <Text fontSize={"sm"} color={"casBlueGrey"}>
          {t("common.step", { count: steps.length })} {steps.length} /{" "}
          {steps.length}
        </Text>
      </HStack>
      <List spacing={2}>
        {steps.map((step) => {
          return (
            <ListItem key={step.title}>
              <HStack>
                <Icon as={MdCheckCircle} boxSize={6} color={"green.500"} />
                <Text color={"blackAlpha.800"} flex={1}>
                  {step.title}
                </Text>
              </HStack>
            </ListItem>
          );
        })}
      </List>
    </VStack>
  );
};

const QUERY = gql`
  query Profiles {
    profiles {
      ...Profile
    }
  }
  ${profileFragments}
`;

const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $user_id: String!
    $onboarding_status: onboarding_status!
  ) {
    update_profiles(
      where: { user_id: { _eq: $user_id } }
      _set: { onboarding_status: $onboarding_status }
    ) {
      returning {
        id
        onboarding_status
      }
    }
  }
`;

type OnboardingStatus =
  | "EMAIL_VERIFIED"
  | "PROFILE_SETUP"
  | "BUDGET_SETUP"
  | "ACCOUNT_LINKING"
  | "COMPLETE"
  | "REMOVED";
