export type AppConfig = {
  authClientId: string;
  authDomain: string;
  authAudience: string;
  gqlApi: string;
  biDomain: string;
  biClientId: string;
  biClientSecret: string;
  baseUrl: string;
  sentryUrl: string;
  env: string;
  hotjarId: number;
  hotjarVersion: number;
  ffPublishableKey: string;
  ffEnvironmentId: string;
  ffSpaceId?: string;
  isLocalhost: boolean;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
};

const config: AppConfig = {
  isLocalhost: window.location.hostname === "localhost",
  authClientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID as string,
  authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN as string,
  authAudience: import.meta.env.VITE_APP_AUTH_AUDIENCE as string,
  gqlApi: import.meta.env.VITE_APP_GQL_ENDPOINT as string,
  biDomain: import.meta.env.VITE_APP_BUDGEA_API_URL as string,
  biClientId: import.meta.env.VITE_APP_BI_CLIENT_ID as string,
  biClientSecret: import.meta.env.VITE_APP_BI_CLIENT_SECRET as string,
  baseUrl: import.meta.env.VITE_APP_BASE_URL as string,
  sentryUrl: import.meta.env.VITE_APP_SENTRY_URL as string,
  env: import.meta.env.MODE,
  hotjarId: parseInt(import.meta.env.VITE_APP_HOTJAR_ID, 10) as number,
  hotjarVersion: parseInt(
    import.meta.env.VITE_APP_HOTJAR_VERSION,
    10,
  ) as number,
  ffPublishableKey: import.meta.env.VITE_APP_FF_PUBLISHABLE_KEY as string,
  ffEnvironmentId: import.meta.env.VITE_APP_FF_ENVIRONMENT as string,
  ffSpaceId: import.meta.env.VITE_APP_FF_SPACE_ID as string,
  firebase: {
    apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY as string,
    authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN as string,
    projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID as string,
    storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: import.meta.env
      .VITE_APP_FIREBASE_MESSAGING_SENDER_ID as string,
    appId: import.meta.env.VITE_APP_FIREBASE_APP_ID as string,
  },
};

export default config;
