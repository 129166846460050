import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Suspense, useRef } from "react";
import { gql, useMutation, useSuspenseQuery } from "@apollo/client";
import {
  EditAccountAccountSummaryFragment,
  EditAccountsQuery,
  RenewConnectionMutation,
  RenewConnectionMutationVariables,
  SyncConnectionMutation,
  SyncConnectionMutationVariables,
} from "../generated/graphql";
import { Currency } from "../components/Currency";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UpdatedAt } from "../components/UpdatedAt.tsx";
import { MdWarning } from "react-icons/md";

export const EditAccounts = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleAcknowledge = () => {
    setSearchParams({ tab: searchParams.get("tab") as string });
  };

  const mapError = (error: string) => {
    switch (error) {
      case "internal":
        return "settings.linkAccountErrorDescription";
      case "ais_connection_error":
        return "settings.GoCardlessTechnicalError";
      case "expired":
        return "connection.expiredDescription";
      case "error":
        return "settings.linkAccountErrorDescription";
      case "UnknownError":
        return "aisError.UnknownError";
      case "SSNVerificationFailed":
        return "aisError.SSNVerificationFailed";
      case "InstitutionTechnicalError":
        return "aisError.InstitutionTechnicalError";
      case "GoCardlessTechnicalError":
        return "aisError.GoCardlessTechnicalError";
      case "UserCancelledSession":
        return "aisError.UserCancelledSession";
      case "UserSessionRejected":
        return "aisError.UserSessionRejected";
      case "NoAccountsConnected":
        return "aisError.NoAccountsConnected";
      case "ConsentLinkReused":
        return "aisError.ConsentLinkReused";
      case "InstitutionUnavailable":
        return "aisError.InstitutionUnavailable";
      default:
        return "settings.linkAccountErrorDescription";
    }
  };

  return (
    <VStack alignItems={"stretch"} spacing={8}>
      {searchParams.get("status") === "error" && (
        <Alert status="error">
          <AlertIcon />
          <Box flex={1}>
            <AlertTitle>{t("settings.linkAccountErrorTitle")}</AlertTitle>
            <AlertDescription>
              {t(mapError(searchParams.get("reason") as string))}
            </AlertDescription>
          </Box>
          <CloseButton
            onClick={handleAcknowledge}
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
          />
        </Alert>
      )}
      {searchParams.get("status") === "success" && (
        <Alert status="success">
          <AlertIcon />
          <Box flex={1}>
            <AlertTitle>{t("settings.linkAccountSuccess")}</AlertTitle>
          </Box>
          <CloseButton
            onClick={handleAcknowledge}
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
          />
        </Alert>
      )}
      <VStack spacing={4} alignItems={"stretch"}>
        <HStack justifyContent={"flex-end"}>
          <Button
            as={Link}
            variant={"primary"}
            alignSelf={"flex-end"}
            to={"/add-account/select"}
            size={"sm"}
          >
            {t("addAccount.self")}
          </Button>
        </HStack>
        <Suspense fallback={<Spinner />}>
          <AccountsList />
        </Suspense>
      </VStack>
    </VStack>
  );
};

const AccountsList = () => {
  const { t, i18n } = useTranslation();
  const { data } = useSuspenseQuery<EditAccountsQuery>(EDIT_ACCOUNTS);
  const toastr = useToast();
  const [syncConnection, { loading: syncingConnection }] = useMutation<
    SyncConnectionMutation,
    SyncConnectionMutationVariables
  >(SYNC_CONNECTION);
  const [renewConnection, { loading: renewingConnection }] = useMutation<
    RenewConnectionMutation,
    RenewConnectionMutationVariables
  >(RENEW_CONNECTION);
  const [deleteConnection, { loading: deletingConnection }] = useMutation(
    DELETE_CONNECTION,
    {
      refetchQueries: ["EditAccounts"],
      onCompleted: () => {
        toastr({
          status: "success",
          title: t("settings.deleteAccountSuccess"),
        });
      },
      onError: () => {
        toastr({
          status: "error",
          title: t("settings.deleteAccountError"),
        });
      },
    },
  );
  const [deleteAccount, { loading: deletingAccount }] = useMutation(
    DELETE_ACCOUNT,
    {
      refetchQueries: ["EditAccounts"],
      onCompleted: () => {
        toastr({
          status: "success",
          title: t("settings.deleteAccountSuccess"),
        });
      },
      onError: () => {
        toastr({
          status: "error",
          title: t("settings.deleteAccountError"),
        });
      },
    },
  );

  const handleSyncConnection = async (connectionId: string) => {
    await syncConnection({
      variables: {
        connection_id: connectionId,
      },
      refetchQueries: ["EditAccounts"],
      onCompleted: () => {
        toastr({
          status: "success",
          title: t("settings.syncConnectionSuccess"),
        });
      },
      onError: () => {
        toastr({
          status: "error",
          title: t("settings.syncConnectionErrorTitle"),
          description: t("settings.syncConnectionErrorDescription"),
        });
      },
    });
  };

  const handleRenewConnection = async (connectionId: string) => {
    await renewConnection({
      variables: {
        input: {
          connection_id: connectionId,
          success_redirect_uri: `${window.location.origin}/settings?tab=accounts`,
          error_redirect_uri: `${window.location.origin}/settings?tab=accounts`,
          lang: i18n.language.substring(0, 2),
        },
      },
      onCompleted: (data) => {
        if (!data.renew_connection) return;
        window.location.href = data?.renew_connection?.link;
      },
      onError: () => {
        toastr({
          status: "error",
          title: t("settings.renewConnectionErrorTitle"),
          description: t("settings.renewConnectionErrorDescription"),
        });
      },
    });
  };

  const handleDeleteAccount = async (
    account: EditAccountAccountSummaryFragment,
  ) => {
    if (account.connection) {
      await deleteConnection({
        variables: {
          connection_id: account.connection.id,
        },
      });
    } else {
      await deleteAccount({
        variables: {
          account_id: account.id,
        },
      });
    }
  };

  if (!data?.institutions?.length) {
    return (
      <Center p={8}>
        <Heading size={"sm"} color={"casBlueGrey"}>
          {t("settings.noAccounts")}
        </Heading>
      </Center>
    );
  }

  return data?.institutions?.map((institution) => (
    <VStack spacing={4} key={institution.id} alignItems={"stretch"}>
      <Heading color={"casDarkBlue"} size={"sm"}>
        {institution?.name}
      </Heading>
      <VStack spacing={2} alignItems={"stretch"}>
        <Accordion allowToggle>
          {institution.accounts.map((account) => (
            <AccordionItem key={account.id}>
              <AccordionButton _hover={{ bg: "blue.50" }}>
                <VStack
                  alignItems={"stretch"}
                  spacing={1}
                  flex={1}
                  justifyContent={"flex-start"}
                >
                  <HStack
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    spacing={1}
                    flex={1}
                  >
                    <HStack>
                      <Text textAlign={"left"}>{account.name}</Text>
                      {account.connection &&
                        ["error", "expired"].includes(
                          typeof account.connection.state === "string"
                            ? account.connection.state
                            : "",
                        ) && <Icon as={MdWarning} color={"red.500"} />}
                    </HStack>
                    <Currency value={account.balance} />
                  </HStack>
                  <Box alignSelf={"flex-start"} textAlign={"right"}>
                    <UpdatedAt date={account.updated_at} />
                  </Box>
                </VStack>
                <AccordionIcon ml={2} />
              </AccordionButton>
              <AccordionPanel>
                <VStack alignItems={"flex-start"} spacing={2} mt={2}>
                  {account.connection && (
                    <Badge
                      colorScheme={
                        stateColorMap[account.connection.state || "pending"]
                      }
                      alignSelf={"flex-start"}
                    >
                      {t(`connection.state.${account.connection.state}`)}
                    </Badge>
                  )}
                  {account.connection?.state === "expired" && (
                    <Text fontSize={"sm"} color={"gray.500"}>
                      {t("connection.expiredDescription")}
                    </Text>
                  )}
                  <ButtonGroup size={"sm"}>
                    <DeleteAccount
                      isDeleting={deletingConnection || deletingAccount}
                      data={account as EditAccountAccountSummaryFragment}
                      onDelete={handleDeleteAccount}
                    />
                    {account.connection?.state === "expired" && (
                      <Button
                        variant={"link"}
                        onClick={() =>
                          handleRenewConnection(
                            account.connection?.id as string,
                          )
                        }
                        isLoading={renewingConnection}
                      >
                        {" "}
                        {t("connection.renew")}
                      </Button>
                    )}
                    {account.connection?.state === "active" && (
                      <Button
                        variant={"link"}
                        isDisabled={account.connection?.state !== "active"}
                        onClick={() =>
                          handleSyncConnection(account.connection?.id as string)
                        }
                        isLoading={syncingConnection}
                      >
                        {t("settings.syncConnection")}
                      </Button>
                    )}
                  </ButtonGroup>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </VStack>
  ));
};

interface DeleteAccountProps {
  data: EditAccountAccountSummaryFragment;
  onDelete: (account: EditAccountAccountSummaryFragment) => void;
  isDeleting: boolean;
}

const DeleteAccount = ({ data, onDelete, isDeleting }: DeleteAccountProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const name = data.name;
  const challenge = data.name;
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      challenge: "",
    },
  });
  const handleConfirmDeleteConnection = async () => {
    onDelete(data);
    onClose();
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Button
        colorScheme={"red"}
        variant={"link"}
        onClick={onOpen}
        isLoading={isDeleting}
      >
        {t("settings.deleteAccountButton")}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            as={"form"}
            onSubmit={handleSubmit(handleConfirmDeleteConnection)}
          >
            <AlertDialogHeader>
              <Trans i18nKey={"settings.deleteAccountTitle"} values={{ name }}>
                Delete Account <em>{name}</em>
              </Trans>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{t("settings.deleteAccountDescription")}</Text>
              <FormControl size={"sm"} mt={2} isInvalid={!!errors?.challenge}>
                <FormLabel>
                  <Trans
                    i18nKey={"settings.deleteAccountChallengeText"}
                    values={{ challenge }}
                  >
                    Please enter <em>{challenge}</em> to confirm the deletion of
                    this account.
                  </Trans>
                </FormLabel>
                <Input
                  type={"text"}
                  {...register("challenge", {
                    required: true,
                    validate: (value) => value === data.name,
                  })}
                />
                {errors.challenge && (
                  <FormErrorMessage>
                    {t("settings.deleteAccountChallengeError")}
                  </FormErrorMessage>
                )}
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCancel}>
                {t("common.cancel")}
              </Button>
              <Button
                colorScheme="red"
                type={"submit"}
                ml={3}
                isLoading={isDeleting}
              >
                {t("settings.deleteAccountButton")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const stateColorMap: Record<string, string> = {
  pending: "blackAlpha",
  error: "red",
  expired: "orange",
  active: "green",
};

EditAccounts.fragments = {
  connection: gql`
    fragment EditAccountConnection on connections_new {
      id
      external_id
      state
      updated_at
    }
  `,
  account: gql`
    fragment EditAccountAccountSummary on accounts_new {
      id
      name
      balance
      updated_at
      type
      connection {
        ...EditAccountConnection
      }
    }
  `,
  institution: gql`
    fragment EditAccountInstitution on institutions {
      id
      name
    }
  `,
  profile: gql`
    fragment EditAccountsProfile on profiles {
      id
      timezone
    }
  `,
};

const EDIT_ACCOUNTS = gql`
  query EditAccounts {
    profiles {
      ...EditAccountsProfile
    }
    institutions(
      order_by: { name: asc }
      where: { accounts_aggregate: { count: { predicate: { _gt: 0 } } } }
    ) {
      ...EditAccountInstitution
      accounts(order_by: { balance: desc }) {
        ...EditAccountAccountSummary
      }
    }
  }
  ${EditAccounts.fragments.connection}
  ${EditAccounts.fragments.account}
  ${EditAccounts.fragments.profile}
  ${EditAccounts.fragments.institution}
`;

const DELETE_CONNECTION = gql`
  mutation DeleteConnection($connection_id: String!) {
    delete_connection(connection_id: $connection_id) {
      message
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($account_id: uuid!) {
    delete_accounts_new_by_pk(id: $account_id) {
      id
    }
  }
`;

const SYNC_CONNECTION = gql`
  mutation SyncConnection($connection_id: String!) {
    sync_connections_one(connection_id: $connection_id) {
      message
    }
  }
`;

const RENEW_CONNECTION = gql`
  mutation RenewConnection($input: RenewConnectionInput!) {
    renew_connection(input: $input) {
      link
      id
      external_id
    }
  }
`;
