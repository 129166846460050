import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdPercent } from "react-icons/md";
import { useMemo } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  EditBudgetQuery,
  InsertUserBudgetMutation,
} from "../generated/graphql";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useAuth } from "../Auth/useAuth.ts";

export const EditBudget = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const toast = useToast();
  const allocationRegisterOptions = useMemo(
    () => ({
      required: true,
      valueAsNumber: true,
    }),
    [],
  );

  const { data: initialData } = useQuery<EditBudgetQuery>(GET_BUDGET, {
    variables: {
      user_id: user?.uid,
    },
    skip: !user,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    values: {
      needsAllocation: initialData?.users_by_pk?.budget?.needs_allocation,
      wantsAllocation: initialData?.users_by_pk?.budget?.wants_allocation,
      goalsAllocation: initialData?.users_by_pk?.budget?.goals_allocation,
      totalAllocation: 100,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const [saveBudget, { loading, error }] =
    useMutation<InsertUserBudgetMutation>(SAVE_BUDGET);

  const onSubmit = async (formData: FieldValues) => {
    await saveBudget({
      variables: {
        budget: {
          needs_allocation: formData.needsAllocation,
          wants_allocation: formData.wantsAllocation,
          goals_allocation: formData.goalsAllocation,
        },
      },
      onCompleted: () => {
        toast({
          title: t("budgetSetup.saveSuccessTitle"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: t("budgetSetup.saveErrorTitle"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
      refetchQueries: ["SpendingDetails"],
    });
  };
  return (
    <VStack
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
      spacing={4}
      alignItems={"flex-start"}
      maxW={{ base: "full", md: "sm" }}
    >
      {!!error && (
        <Alert status={"error"}>
          <AlertIcon />
          <AlertDescription>
            {t("gqlError.error", { error: error.name })}
          </AlertDescription>
        </Alert>
      )}

      {!!errors.totalAllocation && (
        <Alert status={"error"}>
          <AlertIcon />
          <AlertDescription>
            {t("budgetSetup.totalAllocationError")}
          </AlertDescription>
        </Alert>
      )}

      <FormControl isRequired isInvalid={!!errors.needsAllocation}>
        <FormLabel>{t("category.needs")}</FormLabel>
        <InputGroup>
          <Input
            type="number"
            {...register("needsAllocation", allocationRegisterOptions)}
            placeholder="0"
          />
          <InputRightElement>
            <MdPercent />
          </InputRightElement>
        </InputGroup>
        <FormHelperText fontSize="sm">
          {t("budgetSetup.needsAllocationLabel")}
        </FormHelperText>
        <FormErrorMessage>{errors.needsAllocation?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.wantsAllocation}>
        <FormLabel>{t("category.wants")}</FormLabel>
        <InputGroup>
          <Input
            type="number"
            {...register("wantsAllocation", allocationRegisterOptions)}
            placeholder="0"
          />
          <InputRightElement>
            <MdPercent />
          </InputRightElement>
        </InputGroup>
        <FormHelperText fontSize="sm">
          {t("budgetSetup.wantsAllocationLabel")}
        </FormHelperText>
        <FormErrorMessage>{errors.wantsAllocation?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.goalsAllocation}>
        <FormLabel>{t("category.goals")}</FormLabel>
        <InputGroup>
          <Input
            type="number"
            {...register("goalsAllocation", allocationRegisterOptions)}
            placeholder="0"
          />
          <InputRightElement>
            <MdPercent />
          </InputRightElement>
        </InputGroup>
        <FormHelperText fontSize="sm">
          {t("budgetSetup.goalsAllocationLabel")}
        </FormHelperText>
        <FormErrorMessage>{errors.goalsAllocation?.message}</FormErrorMessage>
      </FormControl>
      <ButtonGroup>
        <Button
          variant={"primary"}
          type="submit"
          isLoading={loading}
          isDisabled={!isEmpty(errors)}
        >
          {t("common.save")}
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

const GET_BUDGET = gql`
  query EditBudget($user_id: String!) {
    users_by_pk(auth_provider_id: $user_id) {
      id
      budget {
        needs_allocation
        wants_allocation
        goals_allocation
      }
    }
  }
`;

const SAVE_BUDGET = gql`
  mutation SaveBudget($budget: budgets_insert_input!) {
    insert_budgets_one(
      object: $budget
      on_conflict: {
        constraint: budgets_user_id_key
        update_columns: [wants_allocation, needs_allocation, goals_allocation]
      }
    ) {
      needs_allocation
      goals_allocation
      wants_allocation
    }
  }
`;

interface FormData {
  needsAllocation: number;
  wantsAllocation: number;
  goalsAllocation: number;
  totalAllocation: number;
}
